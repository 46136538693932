<div class="main-into-container">
  <div class="background-img-snow"></div>
  <div class="decorator"></div>
  <div id="bergfuehrer" class="bergfuehrer container">
    <img class="icon-img" width="100px" src="../../assets/icons/noun_Carabiner_45153(1).png"/>
    <h3 class="title"><b>Bergführer</b> </h3>
    <ng-container *ngIf="innerWidth < 410" class="container">
      <div *ngFor="let i of [0,1,2,3]" class="row">
        <div class="col">
          <app-member [member]="members[i]"></app-member>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="innerWidth > 780" class="container">
      <div class="row">
        <div class="col">
          <app-member [member]="members[0]"></app-member>
        </div>
        <div class="col">
          <app-member [member]="members[1]"></app-member>
        </div>
        <div class="col">
          <app-member [member]="members[2]"></app-member>
        </div>
        <div class="col">
          <app-member [member]="members[3]"></app-member>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="innerWidth < 780 && innerWidth > 410" class="container">
      <div class="row">
        <div class="col">
          <app-member [member]="members[0]"></app-member>
        </div>
        <div class="col">
          <app-member [member]="members[1]"></app-member>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <app-member [member]="members[2]"></app-member>
        </div>
        <div class="col">
          <app-member [member]="members[3]"></app-member>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="decorator"></div>
  <div class="background-img-snow"></div>
  <div class="decorator"></div>
  <div id="bergwanderfuehrer" class="bergwanderfuehrer container">
    <img class="icon-img" width="100px" src="../../assets/icons/noun_Mountain Climbing_45240(2).png"/>
    <h3 class="title">Bergwanderführer</h3>
    <ng-container *ngIf="innerWidth < 410" class="container">
      <div *ngFor="let i of [4,5,6]" class="row">
        <div class="col">
          <app-member [member]="members[i]"></app-member>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="innerWidth > 780" class="container">
      <div class="row">
        <div class="col">
          <app-member [member]="members[4]"></app-member>
        </div>
        <div class="col">
          <app-member [member]="members[5]"></app-member>
        </div>
        <div class="col">
          <app-member [member]="members[6]"></app-member>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="innerWidth < 780 && innerWidth > 410" class="container">
      <div class="row">
        <div class="col">
          <app-member [member]="members[4]"></app-member>
        </div>
        <div class="col">
          <app-member [member]="members[5]"></app-member>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <app-member [member]="members[6]"></app-member>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="decorator"></div>
  <div [style]="{marginBottom: '3rem'}" class="container galleria">
    <app-gallery></app-gallery>
  </div>
  <div class="decorator"></div>
  <div class="background-img"></div>
  <div class="offers">
    <h2 class="offers-title">Unser Angebot <i style="color: red" class="fas fa-heart"></i></h2>
    <div class="offers-list">
      <ul class="fa-ul">
        <li class="list-element" *ngFor="let offer of offers"><span class="fa-li"><i class="fas fa-mountain"></i></span>{{offer}}</li>
      </ul>
    </div>
  </div>
  <div class="decorator"></div>
</div>
