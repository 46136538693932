<div class="homepage-welcome-title"><h2>Bergführerverein Matrei in Osttirol</h2></div>
<div id="tariffs" class="container tariffs-container">
  <h1 class="tariffs-title">Unsere Tarife <i style="margin-left: 1rem" class="fas fa-info"></i></h1>
  <div class="row tariffs">
    <div class="col-md-6">
      <div class="tariffs-icon-container">
        <div class="hover-div" (click)="showModal(basicModal, tariffsType.summer)" data-toggle="modal">
          <span class="tariffs-icon-summer"><i class="fas fa-sun"></i></span>
          <h3 class="tariffs-label"><b>Sommer Tarife</b></h3>
        </div>
        <p class="tariffs-text">
          Holen Sie sich jetzt alle Informationen für unsere Sommer Touren!
        </p>
      </div>
      <div mdbModal #basicModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" style="overflow: scroll"
           aria-labelledby="myBasicModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-fluid" style="max-width: 80%" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <p class="heading lead" style="display: contents; color: white">Sommer Tarife</p>
              <button type="button" class="close pull-right" aria-label="Close" (click)="basicModal.hide()">
                <span aria-hidden="true" style="color: white">×</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="text-center">
                <div style="position: relative" *ngIf="basicModal.isShown">
                  <simple-pdf-viewer [src]="summerPdf">
                  </simple-pdf-viewer>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close"
                      (click)="basicModal.hide()" mdbWavesEffect>Close
              </button>
              <button  type="button" mdbBtn color="primary" class="relative waves-light" mdbWavesEffect (click)="downloadPDF(tariffsType.summer)">Download</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="tariffs-icon-container">
        <div class="hover-div" (click)="showModal(basicModal2, tariffsType.winter)">
          <span class="tariffs-icon-winter"><i class="fas fa-skiing-nordic"></i></span>
          <h3 class="tariffs-label"><b>Winter Tarife</b></h3>
        </div>
        <p class="tariffs-text">
          Holen Sie sich jetzt alle Informationen für unsere Winter Touren!
        </p>
      </div>
      <div mdbModal #basicModal2="mdbModal" class="modal fade" tabindex="-1" role="dialog" style="overflow: scroll"
           aria-labelledby="myBasicModalLabel1" aria-hidden="true">
        <div class="modal-dialog modal-fluid" style="max-width: 80%" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <p class="heading lead" style="display: contents; color: white">Winter Tarife Blatt</p>
              <button type="button" class="close pull-right" aria-label="Close" (click)="basicModal2.hide()">
                <span aria-hidden="true" style="color: white">×</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="text-center">
                <div style="position: relative" *ngIf="basicModal2.isShown">
                  <simple-pdf-viewer [src]="winterPdf">
                  </simple-pdf-viewer>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close"
                      (click)="basicModal2.hide()" mdbWavesEffect>Close
              </button>
              <button type="button" mdbBtn color="primary" class="relative waves-light" mdbWavesEffect (click)="downloadPDF(tariffsType.winter)">Download</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="decorator"></div>
