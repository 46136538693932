<div class="header-slider">
  <mdb-carousel class="carousel slide carousel-fade" [animation]="'fade'" [interval]="10000">
    <mdb-carousel-item *ngFor="let src of this.sliderImgsSrc; index as i">
      <div class="view w-100">
        <img class="d-block w-100" [src]="src" height="800px" alt="First slide">
        <div class="mask rgba-black-light waves-light" mdbWavesEffect></div>
      </div>
      <div class="carousel-caption">
        <h2 class="h3-responsive">{{this.sliderHeaderList[i]}}</h2>
        <p>{{this.sliderTextList[i]}}</p>
      </div>
    </mdb-carousel-item>
  </mdb-carousel>
</div>

