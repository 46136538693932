import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MDBModalRef, ModalDirective} from 'angular-bootstrap-md/lib/free/modals';
export enum TariffsType {
  'summer'= 'summer',
  'winter' = 'winter'
}

@Component({
  selector: 'app-tariffs',
  templateUrl: './tariffs.component.html',
  styleUrls: ['./tariffs.component.scss']
})
export class TariffsComponent implements OnInit {
  summerPdf = '/assets/tariffs/Tarifblatt_BFMatrei_2023_sommer.pdf';
  winterPdf = '/assets/tariffs/Tarifblatt_BFMatrei_2023_winter.pdf';
  tariffsType = TariffsType;
  @ViewChild('basicModal') public basicModal: MDBModalRef;
  @ViewChild('basicModal2') public basicModal2: MDBModalRef;

  constructor() { }

  ngOnInit(): void {
  }

  downloadPDF(tariffType: TariffsType){
    const link = document.createElement('a');
    switch (tariffType){
      case TariffsType.summer:
        link.download = 'Tarifblatt_BFMatrei_2023_sommer';
        link.href = this.summerPdf;
        link.click();
        break;
      case TariffsType.winter:
        link.download = 'Tarifblatt_BFMatrei_2023_winter';
        link.href = this.winterPdf;
        link.click();
        break;
    }
  }

  showModal(basicModal: any, tariffType: TariffsType) {
    if (window.innerWidth < 500 ){
      this.downloadPDF(tariffType);
    }else {
      basicModal.show();
    }
  }
}
