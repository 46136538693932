<!-- Footer -->
<footer class="page-footer font-small mb-footer pt-4">

  <!-- Footer Links -->
  <div class="container text-center text-md-left">

    <!-- Footer links -->
    <div class="row text-center text-md-left mt-3 pb-3">

      <!-- Grid column -->
      <div class="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
        <h6 class="text-uppercase mb-4 font-weight-bold">{{companyName}}</h6>
      </div>
      <!-- Grid column -->

      <hr class="w-100 clearfix d-md-none">

      <!-- Grid column -->
      <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3">
        <h6 class="text-uppercase mb-4 font-weight-bold">Navigation</h6>
        <p>
          <a href="#tariffs">Tarife</a>
        </p>
        <p>
          <a href="#bergfuehrer">Bergführer</a>
        </p>
        <p>
          <a href="#bergwanderfuehrer">Bergwanderführer</a>
        </p>
        <p>
          <a href="#galleria">Gallerie</a>
        </p>
      </div>
      <!-- Grid column -->

      <hr class="w-100 clearfix d-md-none">

      <!-- Grid column -->
      <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
        <h6 class="text-uppercase mb-4 font-weight-bold">Kontakt</h6>
        <p>
          <mdb-icon fas icon="home" class="mr-3"></mdb-icon> Marstallweg 17, A-9971 Matrei
        </p>
        <p>
          <mdb-icon fas icon="envelope" class="mr-3"></mdb-icon> info@bergfuehrer-matrei.at
        </p>
        <p>
          <mdb-icon fas icon="phone" class="mr-3"></mdb-icon> + 43 664 1023873
        </p>
      </div>
      <!-- Grid column -->

    </div>
    <!-- Footer links -->

    <hr>

    <!-- Grid row -->
    <div class="row d-flex align-items-center">

      <!-- Grid column -->
      <div class="col-md-7 col-lg-8">

        <!--Copyright-->
        <p class="text-center text-md-left">© {{date}} Copyright:
          <strong>Bergführerverein Matrei in Osttirol</strong>
        </p>

      </div>
      <!-- Grid column -->

      <!-- Grid column -->
      <div class="social-buttons col-md-5 col-lg-4 ml-lg-0">

        <!-- Social buttons -->
        <section style="float: right;" class="mb-4">
          <!-- Facebook -->
          <a
            class="btn btn-floating m-1"
            style="background-color: #3b5998;"
            href="#!"
            role="button"
          ><i class="fab fa-facebook-f"></i
          ></a>

          <!-- Instagram -->
          <a
            class="btn btn-floating m-1"
            style="background-color: #ac2bac;"
            href="#!"
            role="button"
          ><i class="fab fa-instagram"></i
          ></a>
        </section>

      </div>
      <!-- Grid column -->

    </div>
    <!-- Grid row -->

  </div>
  <!-- Footer Links -->

</footer>
<!-- Footer -->
