<!-- Card -->
<mdb-card>
  <div class="view rgba-white-slight waves-light" mdbWavesEffect>
    <!-- Card img -->
    <mdb-card-img src="{{member.picLink}}" alt="member"></mdb-card-img>
    <a>
      <div class="mask"></div>
    </a>
  </div>
  <!--Card content-->
  <mdb-card-body>
    <!--Title-->
    <mdb-card-title style="text-align: center">
      <h4>{{member.name}}</h4>
    </mdb-card-title>

    <!--Text-->
    <mdb-card-text>
      <p>
        <mdb-icon fas icon="envelope" style="margin-right: .1rem !important;"></mdb-icon> <a href="mailto:{{member.email}}">{{member.email}}</a>
      </p>
      <p>
        <mdb-icon fas icon="home" style="margin-right: .1rem !important;"></mdb-icon> <a href="{{member.website}}">{{member.shortWebUri}}</a>
      </p>
    </mdb-card-text>
  </mdb-card-body>
</mdb-card>
<!-- Card -->
